I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "actions": {
    "default": {
      "actions": "Actions",
      "add": "Add",
      "apply_filter": "Apply filter",
      "approve": "Approve",
      "are_you_sure": "Are you sure?",
      "back": "Back",
      "browse": "Browse",
      "cancel": "Cancel",
      "continue": "Continue",
      "copied": "Copied",
      "copy_to_clipboard": "Copy to Clipboard",
      "custom_chart": "Custom chart",
      "custom_export": "Custom Export",
      "delete": "Delete",
      "disable": "Disable",
      "download_file": "Download %{file_type}",
      "edit": "Edit",
      "enable": "Enable",
      "export_all_files": "Export All Files",
      "export_extended_epa_in_csv": "Export Extended EPA in CSV",
      "export_extended_epa_in_xls": "Export Extended EPA in XLS",
      "export_extended_epa_in_xml": "Export Extended EPA in XML",
      "export_files": "Export Files",
      "export_in_pdf": "Export in PDF",
      "export_selected_files": "Export Selected Files",
      "export_standard_epa_in_csv": "Export Standard EPA in CSV",
      "export_standard_epa_in_xls": "Export Standard EPA in XLS",
      "export_standard_epa_in_xml": "Export Standard EPA in XML",
      "export_to_csv": "Export to CSV",
      "export_to_excel": "Export to Excel",
      "generate": "Generate",
      "generate_csv": "Generate CSV",
      "generate_xls": "Generate XLS",
      "please_select": "Please Select...",
      "process": "Process",
      "process_payment": "Process payment",
      "regenerate": "Regenerate",
      "remove": "Remove",
      "reset": "Reset",
      "save": "Save",
      "save_preferences": "Save preferences",
      "search": "Search",
      "select_all": "Select all",
      "select_fields": "Select Fields",
      "show_deltas": "Show deltas",
      "show_graph": "Show graph",
      "submit": "Submit",
      "toggle": "toggle",
      "undo": "Undo",
      "update": "Update",
      "upload": "Upload",
      "upload_file": "Upload file",
      "view_details": "View Details"
    },
    "object": {
      "configure_new": "Configure new %{obj}",
      "create": "Create %{obj}",
      "delete": "Delete %{obj}",
      "edit": "Edit %{obj}",
      "new": "New %{obj}",
      "save": "Save %{obj}",
      "select": "Select %{obj}",
      "show": "Show %{obj}",
      "update": "Update %{obj}",
      "view": "View %{obj}"
    }
  },
  "common": {
    "both": "Both",
    "copy_paste_link": "or copy and paste the link below in your browser:",
    "count": "Count",
    "details": "Details",
    "ecp_company": "E-Comprocessing",
    "emp_company": "eMerchantPay",
    "head_title": "%{subject} - Genesis",
    "info": "Info",
    "no": "No",
    "object": {
      "configuration": "%{obj} Configuration",
      "details": "%{obj} Details"
    },
    "summary": "Summary",
    "team": "Team",
    "thank_you": "Thank you,",
    "total": "Total",
    "workflow": "Workflow",
    "yes": "Yes",
    "your": "your"
  },
  "contracts": {
    "form": {
      "acquirer": "Acquirer",
      "activated": "Activated",
      "authentication_service": "Authentication Service",
      "authorize_timeframe": "Authorize timeframe",
      "bank_account_number": "Bank Account Number",
      "credential1": "Credential1",
      "credential2": "Credential2",
      "custom_directory_server": "Custom Directory Server",
      "descriptor": "Descriptor",
      "did": "DID",
      "directory_server": "Directory Server",
      "enabled": "Enabled",
      "expires_at": "Expires at",
      "features": "Features",
      "gateway": "Gateway",
      "is_template": "Is template",
      "login": "Login",
      "mcc": "Merchant Category Code (MCC)",
      "merchant": "Merchant",
      "merchant_bank_id": "Merchant Bank ID",
      "merchant_certificate_password": "Merchant Certificate Password",
      "merchant_certificate_subject": "Merchant Certificate Subject",
      "merchant_city": "Merchant City",
      "merchant_country": "Merchant Country",
      "merchant_country_of_origin_must_be_government_controlled": "Should be used only for government-controlled merchants",
      "merchant_name": "Merchant Name",
      "merchant_number": "Merchant Number",
      "merchant_number_must_be_n_digits_long": "The merchant number must be %{limit} digits long.",
      "merchant_password": "Merchant Password",
      "merchant_url": "Merchant URL",
      "mode": "Mode",
      "mpi": "MPI",
      "mpi_authentication_status": "MPI Authentication Status",
      "mpi_enrollment_status": "MPI Enrollment Status",
      "mpi_timeout_seconds": "Timeout seconds",
      "name": "Name",
      "password": "Password",
      "paysec_payout": "PaySec Payout",
      "please_select": "Please Select...",
      "refund_email": "Refund email",
      "refund_timeframe": "Refund timeframe",
      "reseller": "Reseller",
      "rpn_withdrawal": "RPN Withdrawal",
      "save_changes": "Save changes",
      "sofort_ideal": "Sofort iDEAL",
      "supports_3ds_v2_protocol": "Supports 3DS v2 protocol",
      "supports_authorize_dissolve": "Supports authorize dissolve",
      "supports_enabled_crypto_transactions": "Supports high-risk/crypto by default",
      "supports_enabled_crypto_transactions_tooltip": "Flags all the purchase and VISA OCT transactions as high-risk/cryptо",
      "supports_mastercard_identity_check_insights": "Supports MasterCard identity check insights",
      "supports_multiple_partial_captures": "Supports multiple partial captures",
      "supports_one_worded_cardholder": "Supports one worded cardholder name",
      "supports_partial_approval": "Supports partial approval",
      "supports_partial_capture": "Supports partial capture",
      "supports_partial_refund": "Supports partial refund",
      "supports_phone_number_validation": "Supports phone number validation",
      "supports_preauthorizations": "Supports preauthorizations",
      "supports_reconcile_by_cron": "Supports reconcile by cron",
      "supports_recurring": "Supports recurring",
      "supports_refund_by_email": "Supports refund by email",
      "supports_scheme_tokenized_transactions": "Supports scheme tokenized transactions",
      "supports_unicode_cardholder": "Supports unicode characters in cardholder name",
      "supports_unicode_cardholder_tooltip": "For Domestic, Intra-Regional and Inter-Europe payouts",
      "supports_vbv_for_not_participating_cards": "Supports vbv for not participating cards",
      "terminal_token": "Terminal Token",
      "timeout_seconds": "Timeout seconds",
      "tip": "Tip:",
      "transaction_types": "Transaction types"
    }
  },
  "custom_exports": {
    "attribute_categories": {
      "airlines": "Airlines and Air Carriers",
      "all": "All",
      "business_attributes": "Business Attributes",
      "car_rentals": "Car, Plane and Boat Rental",
      "cruise_lines": "Cruise Lines",
      "event_management": "Event Management",
      "furniture": "Furniture",
      "general": "General",
      "hotel_rental": "Hotel and Real estate Rental",
      "travel_agencies": "Travel Agencies",
      "travel_data": "Travel Data"
    }
  },
  "email_templates": {
    "add_email_templates": "Add Email Templates",
    "edit": {
      "edit_template": "Edit Template",
      "preview_template": "Preview"
    },
    "edit_email_templates": "Edit Email Templates",
    "errors": {
      "default_language": "Language is already selected!",
      "duplicate_language": "has already been taken!",
      "not_selected": "not selected"
    },
    "form": {
      "back": "Back",
      "configure_template": "Configure Template",
      "preview": "Preview",
      "template_body": "Body",
      "template_channel_option": "Channel Option",
      "template_default_language": "Set As Default",
      "template_language": "Language",
      "template_loading": "Loading",
      "template_name": "Template Name",
      "template_preview": "Preview Template",
      "template_save": "Save Template",
      "template_subject": "Subject",
      "template_type": "Template Type",
      "template_variables": "Variables"
    },
    "index": {
      "all_templates": "All Templates",
      "email_templates": "Email Templates"
    },
    "modal": {
      "create_template": "Create New Template",
      "update_template": "Update Template"
    },
    "new": {
      "create_template": "Create Template",
      "new_template": "New Template"
    },
    "template_table": {
      "channel": "Channel",
      "create_new_template": "Create New Template",
      "default": "Default",
      "languages": "Languages",
      "name": "Template Name",
      "type": "Type"
    },
    "types": {
      "pay_by_link": "Pay By Link",
      "pay_later_1st_reminder": "Pay Later 1st Reminder",
      "pay_later_2nd_reminder": "Pay Later 2nd Reminder",
      "pay_later_3rd_reminder": "Pay Later 3rd Reminder"
    }
  },
  "feature": {
    "emails": {
      "additional_configuration": "Additional Configuration",
      "alert": {
        "final_transaction_status": "If the fields below are not configured we presume that there are no special requirements about them and the email will be sent for all of the transaction types and status transitions."
      },
      "configuration_categories": {
        "general": "General Emails",
        "reminder_types": "Reminder Emails",
        "transaction_types": "Transaction Emails"
      },
      "configuration_types": {
        "authorize": "Authorize",
        "authorize3d": "Authorize 3D",
        "capture": "Capture",
        "chargeback_mailer": "Chargeback Mailer",
        "final_transaction_status": "Final Transaction Status",
        "fraud": "Fraud Mailer",
        "incremental_authorize": "Incremental Authorize",
        "init_recurring_sale": "Init Recurring Sale",
        "init_recurring_sale3d": "Init Recurring Sale 3D",
        "partial_reversal": "Partial reversal",
        "pay_by_link": "Pay By Link",
        "pay_later_1st_reminder": "Pay Later 1st Reminder",
        "pay_later_2nd_reminder": "Pay Later 2nd Reminder",
        "pay_later_3rd_reminder": "Pay Later 3rd Reminder",
        "recurring_sale": "Recurring Sale",
        "refund": "Refund",
        "sale": "Sale",
        "sale3d": "Sale 3D",
        "void": "Void"
      }
    }
  },
  "issuing": {
    "load_operations": {
      "amount": "Amount",
      "card_id": "Card ID",
      "created_at": "Created At",
      "hide": "Hide",
      "id": "ID",
      "payment_id": "Payment ID",
      "show": "Show",
      "status": "Status",
      "status_approved": "Approved",
      "status_declined": "Declined",
      "status_error": "Error",
      "title": "Load Operations",
      "total_successful": "Total successful:"
    }
  },
  "javascript": {
    "actions": {
      "try_again": "Try again"
    },
    "add_pan_confirmation": "Do you really want to add this PAN to the blacklist?",
    "async_multi_select": {
      "no_options": "No options",
      "term_message": "The entered value must be at least %{min_search_length} characters long"
    },
    "attributes": {
      "recurring_advice_text": "Recurring advice text"
    },
    "changes_not_saved": "Changes not saved",
    "changes_saved": "Changes saved",
    "charts": {
      "count": "Count",
      "delta": "Delta",
      "volume": "Volume"
    },
    "choose": "Choose",
    "common": {
      "off": "Off",
      "on": "On",
      "sms": "SMS",
      "tips": "Tips"
    },
    "custom_date_range": "Custom Date Range:",
    "cw": "CW",
    "delete_pan_confirmation": "Do you really want to remove this PAN from the blacklist?",
    "delete_risk_filter_confirmation": "Are you sure you want to delete %{filter}?",
    "delete_risk_filters_confirmation": "Are you sure you want to delete all of the selected Risk filters?",
    "editable": "editable",
    "errors": {
      "copy_fail": "Please copy the text manually.",
      "something_went_wrong": "Something went wrong!",
      "try_again_later": "Please, try again later.",
      "unable_to_process_request": "Sorry, we're unable to process your request."
    },
    "feature": {
      "transactions": {
        "volumes_for_selection": "Volumes for selection"
      }
    },
    "features": {
      "system_status": {
        "index": {
          "show_example_transaction": "Show example transaction"
        }
      }
    },
    "forms": {
      "test_data": {
        "chargeback_item_type": "Item Type",
        "fraud_data_type": "Fraud Data Type",
        "interchange_reject_statuses": "Reject Status"
      }
    },
    "from": "From:",
    "helpers": {
      "application": {
        "mark_note_as_reviewed": "Mark note as reviewed"
      }
    },
    "issuing": {
      "status": {
        "gps_provider": "Gps",
        "paynetics_provider": "Paynetics",
        "transact_provider": "Transact"
      }
    },
    "last_month": "Last month",
    "last_week": "Last week",
    "merchants": {
      "apple_pay_onboarding": {
        "direct_registration": "Direct Registration",
        "download_validation_files": "Download validation file",
        "for_each_domain": "for each domain registered below",
        "host_under": "https://[DOMAIN_NAME]/.well-known/apple-developer-merchantid-domain-association",
        "host_your_files_to": "Host the downloaded validation files under:",
        "invalid_domain_format": "Please use fully qualified domain names, like 'example.com'",
        "max_domains_for_single_request": "The number of domains need to be less than 99 for a single request",
        "merchant_does_not_support_apple_pay": "Merchant does not support Apple Pay",
        "merchant_domains": "Merchant domains",
        "merchant_dont_support_apple_pay": null,
        "onboarding": "Apple Pay Onboarding",
        "please_provide_domains": "Please provide at least one fully qualified domain name",
        "register_domain": "Register domains",
        "successful_registration": "Merchant domains were registered successfully",
        "trying_to_delete_invalid_domain": "This domain does not exist in your Apple Pay merchant account",
        "update_registered_domains": "Merchant domains were updated successfully",
        "validate_your_urls": "Validate your URL for direct access to ApplPay. No need to issue a certificate to register with Apple Developer.",
        "validation_file_not_found": "Please make sure the validation file is hosted correctly at the location indicated above"
      },
      "form": {
        "AI": "Abitab",
        "BB": "Banco do Brasil",
        "BC": "BCP",
        "BCT": "Bancontact",
        "BLK": "BLIK",
        "BN": "BBVA Bancomer",
        "BO": "Banco de Occidente",
        "BP": "BBVA",
        "BQ": "Banorte",
        "BR": "Bradesco",
        "CA": "Caixa",
        "CPI": "Interac Combined Pay-in",
        "EF": "Pago Efectivo",
        "IB": "Interbank",
        "IT": "Itau",
        "PE": "PagoExpress",
        "PF": "Post Finance",
        "PS": "PSE",
        "SE": "Spei",
        "SN": "Santander",
        "SP": "Servipag",
        "SPE": "Spei Direct",
        "TRL": "TrueLayer",
        "WP": "Webpay",
        "allowed_domains": "Allowed domains",
        "allowed_issuing_attributes_card_brands": "Allowed Card Brands",
        "allowed_issuing_attributes_card_types": "Allowed Card Types",
        "allowed_issuing_attributes_currencies": "Allowed Currencies",
        "allowed_issuing_attributes_providers": "Allowed Providers",
        "embed_access": "Embed Access",
        "include_http_or_https": "You must include http or https. Example: 'http://example.com'",
        "issuing_api": "Issuing API",
        "separate_domains_using_comma": "Separate multiple domains using comma.",
        "show_wpf_billing_descriptor": "Show WPF Billing descriptor",
        "show_wpf_card_visualization": "Show WPF Card visualization",
        "show_wpf_customer_address_input": "Show Customer Address input",
        "show_wpf_customer_email_input": "Show Customer Email input",
        "show_wpf_customer_names_input": "Show Customer Names input",
        "show_wpf_customer_phone_input": "Show Customer Phone Number input",
        "show_wpf_fields": "Show WPF Fields",
        "show_wpf_merchant_address": "Show Merchant Address",
        "show_wpf_purchase_summary": "Show Purchase Summary",
        "show_wpf_terms_and_policy_checkbox": "Show WPF Terms and Policy checkbox",
        "show_wpf_usage_input": "Show Usage input",
        "supports_email_domain_validation": "Supports email domain validation",
        "supports_issuing_api": "Supports Issuing API",
        "wpf_top_level_bank_codes": "WPF Top Level Bank Codes"
      },
      "merchant_dont_support_apple_pay": null
    },
    "monday": "Monday",
    "object": {
      "attributes": {
        "recurring_advice_code": "Recurring advice code"
      }
    },
    "options": {
      "please_select": "Please select..."
    },
    "pay_by_link": {
      "generate_payment_link": "Generate payment link",
      "send_payment_link": "Send payment link"
    },
    "pay_pal_onboarding": {
      "check_onboarding_status": "Check onboarding status",
      "check_registration_failed": "Onboarding is not completed. Please, check again later or try to initiate registration process again if the link is no longer valid.",
      "check_registration_successful": "Registration was succesfull.",
      "generate_onboarding_link": "Generate onboarding link",
      "registration_details": "PayPal registration details",
      "registration_successful": "Initiating the registration process was successful. Please, follow the link below to complete the registration."
    },
    "payment_transactions": {
      "volumes_for_selection": {
        "count_approved": "count approved",
        "please_select_currency": "Please select currency",
        "transaction_not_reached_acquirer": "Transaction not reached acquirer",
        "transaction_reached_acquirer": "Transaction reached acquirer",
        "volume_approved": "volume approved"
      }
    },
    "read_only": "read-only",
    "reminders_configs": {
      "edit": {
        "edit_configuration": "Edit Configuration"
      },
      "form": {
        "1st_after": "1st reminder after",
        "2nd_after": "2nd reminder after",
        "3rd_after": "3rd reminder after",
        "channel": "Channel",
        "count_placeholder": "Enter a value between 1 and 3",
        "email": "Email",
        "minutes": "Minutes",
        "reminders_config": "Reminders Config",
        "reminders_count": "Reminders Count"
      },
      "new": {
        "configuration": "Configuration"
      }
    },
    "remove_pan_confirmation": "Do you really want to remove this PAN from the blacklist?",
    "saving_in_progress": "Saving...",
    "select_month": "Select Month:",
    "select_range": "Select Range:",
    "select_week": "Select Week:",
    "session_timeout": "Session timeout!",
    "status": {
      "loading": "Loading"
    },
    "sunday": "Sunday",
    "terminals": {
      "detach_terminal_modal_body": "<strong>%{terminal_name}</strong> will be detached from its template."
    },
    "test_data": {
      "alerts": {
        "generation_scheduled": "Test fraud data scheduled for generation."
      }
    },
    "this_month": "This month",
    "this_week": "This week",
    "to": "To:",
    "travel_rules": {
      "edit": {
        "master": "Master",
        "visa": "Visa"
      }
    },
    "v2": {
      "wpf_custom_theme": {
        "messages": {
          "error_subtitle": "Something went wrong. Try again, please!",
          "error_title": "Error",
          "success_subtitle": "Changes have been saved successfully!",
          "success_title": "Success"
        },
        "styling": {
          "background_color": "Background colour",
          "background_image": "Background image",
          "background_image_info": {
            "format": "Image formats: PNG, JPEG",
            "max_size": "File size: 20 MB max, but ideally, smaller (without sacrificing quality)",
            "size": "Upload a high resolution image. Ideal size 720x1080 for layout 1 and 3, 1920x1080 in case of layout 2 for optimal ratio without quality loss."
          },
          "background_image_upload": "Upload",
          "brand_color": "Brand colour",
          "colors_and_background": "Colours and background",
          "corner_radius": "Corner radius",
          "dark_theme": "Dark",
          "font": "Font",
          "image": "Image",
          "input_size": "Input size",
          "layout_options": "Layout options",
          "layout_options_info": {
            "description": "Layout options specifies the type of positioning method used for all elements in the Web Payment Form.",
            "notice": "Notice that Positions 1 and 3 do not apply for mobile devices - your image will always be positioned in the center of the mobile screen.",
            "position1": "Position 1 - image is positioned on the right",
            "position2": "Position 2 - image is positioned in the center",
            "position3": "Position 3 - image is positioned on the left"
          },
          "light_theme": "Light",
          "merchant_logo": "Merchant logo",
          "merchant_logo_size": "Logo size",
          "shapes": "Shapes",
          "theme": "Theme",
          "title": "Styling"
        }
      }
    },
    "week_start": "Week start:"
  },
  "object": {
    "alert": {
      "deleted": "%{object_type} was deleted",
      "do_you_really_want_to_decrypt": "Do you really want to decrypt this attribute?",
      "saved": "%{object_type} was saved successfully",
      "successfully_update": "%{object_type} was successfully updated",
      "was_not_deleted": "%{object_type} was not deleted",
      "was_not_saved": "%{object_type} was not saved",
      "was_not_update": "%{object_type} was not updated"
    },
    "attributes": {
      "account_amount_gross": "Account Amount Gross",
      "account_amount_net": "Account Amount Net",
      "account_currency": "Account currency",
      "account_device_type": "Account device type",
      "account_holder": "Account holder",
      "account_id": "Account ID",
      "account_number": "Account number",
      "account_owner": {
        "first_name": "First name",
        "last_name": "Last name",
        "middle_name": "Middle Name"
      },
      "account_sequence_number": "Account sequence number",
      "account_status_indicator": "Account status indicator",
      "account_total_charges": "Account Total Charges",
      "account_type": "Account type",
      "account_type_code": "Account Type Code",
      "acquirer": "Acquirer",
      "acquirer_business_id": "Acquirer business ID",
      "acquirer_date": "Date of registration with the Acquirer",
      "acquirer_id": "Acquirer ID",
      "acquirer_reference": "Acquirer reference",
      "acquiring_transit_routing_number": "Acquiring transit routing number",
      "acs_transaction_id": "3DS ACS Transaction ID",
      "action_identifier": "Action Identifier",
      "activated": "Activated",
      "addendum_acquirer_reference": "Addendum ARN",
      "additional_pos_info": "Additional POS Info",
      "address": "Address",
      "address1": "Address 1",
      "address2": "Address 2",
      "address_state": "Address state",
      "admin": "Admin",
      "admin_id": "Admin ID",
      "admin_type": "Admin type",
      "airline_code": "Airline Code",
      "airline_flight_number": "Airline Flight Number",
      "airline_tour_operator_name": "Airline Tour Operator Name",
      "alipay_merchant_json": "Alipay Merchant",
      "allow_manual_3ds_challenge_form_submission": "Allow manual 3DS-Challenge form submission by merchant",
      "allowed_domains": "Allowed domains",
      "allowed_ip_addresses": "Allowed IP Addresses",
      "allowed_issuing_attributes": "Allowed Issuing API Attributes",
      "allowed_reporting_ip_addresses": "Allowed Reporting IP Addresses",
      "alternative_merchant_id": "Alternative Merchant ID",
      "amount": "Amount",
      "amount_in_usd": "Amount in USD",
      "amount_type": "Amount type",
      "approved_count_in_percent": "Approved count in %",
      "approved_credit_count": "Approved Credit Count",
      "approved_credit_volume": "Approved Credit Volume",
      "approved_credit_volume_in_usd": "Approved Credit Volume In USD",
      "approved_volume_in_percent": "Approved volume in %",
      "approved_volume_in_usd": "Approved Volume In USD",
      "area_of_event": "Area of event",
      "arn": "ARN",
      "arrival_date": "Arrival Date",
      "async_params": "Async params",
      "atol_certificate": "ATOL Certificate",
      "auth_code": "Auth code",
      "auth_date": "Authorization date",
      "auth_network_outage_exemption_threshold": "Auth Network Outage Exemption Threshold",
      "auth_response": "Auth Response",
      "auth_response_code": "Authorization Response Code",
      "auth_source": "Auth Source",
      "authcode": "Authcode",
      "authentication_service": "Authentication Service",
      "authentication_status": "Authentication status",
      "authorization_amount": "Authorization Amount",
      "authorization_code": "Authorization code",
      "authorization_record_sequence": "Authorization record sequence",
      "authorize_timeframe": "Authorize timeframe",
      "avs_response_code": "AVS Response",
      "avs_result": "AVS result",
      "background_color": "Background Color",
      "bank": "Bank",
      "bank_account_number": "Bank Account Number",
      "bank_code": "Bank Code",
      "bank_identifier_code": "BIC / SWIFT code",
      "bank_reference_number": "Bank Reference Number",
      "banks": "Banks",
      "barcode": "Barcode",
      "base": "Base",
      "base_currency": "Base Currency",
      "batch_date": "Batch date",
      "batch_id": "Batch",
      "batch_number": "Batch number",
      "batch_slip_number": "Batch slip number",
      "beneficiary_account_number": "Beneficiary Account Number",
      "beneficiary_bank_code": "Beneficiary Bank Code",
      "beneficiary_name": "Beneficiary Name",
      "bic": "BIC",
      "billing_country": "Billing Country",
      "billing_currency": "Currency",
      "bin": "Card Number",
      "bin_country": "Bin country",
      "birth_city": "Birth City",
      "birth_date": "Date of Birth",
      "birth_state": "Birth state",
      "cancel_comment": "Cancel Comment",
      "capture_method": "Capture method",
      "capture_method_code": "Capture method code",
      "captured": "Captured",
      "card_acceptor_id": "Card acceptor ID",
      "card_acceptor_name_loc": "Card Acceptor Name Loc",
      "card_brand": "Card Brand",
      "card_capability": "Card capability",
      "card_category": "Card Category",
      "card_expiration_date": "Card expiration date",
      "card_holder": "Cardholder's name",
      "card_mailing_city": "Card mailing city",
      "card_mailing_date": "Card mailing date",
      "card_mailing_state": "Card mailing state",
      "card_number": "Card number",
      "card_present_indicator": "Card present indicator",
      "card_present_transaction": "Card Present Processed Transaction ID",
      "card_scheme": "Card scheme",
      "card_scheme_code": "Card scheme code",
      "card_sub_type": "Card Subtype",
      "card_subtype": "Card Subtype",
      "card_type": "Card Type",
      "card_valid_from": "Card valid from",
      "cardholder_activated_terminal_indicator": "Cardholder activated terminal indicator",
      "cardholder_address1": "Cardholder address 1",
      "cardholder_address2": "Cardholder address 2",
      "cardholder_city": "Cardholder city",
      "cardholder_first_name": "Cardholder first name",
      "cardholder_id_merthod": "Cardholder ID merthod",
      "cardholder_id_method_used": "Cardholder ID method used",
      "cardholder_last_name": "Cardholder last name",
      "cardholder_middle_initial": "Cardholder middle initial",
      "cardholder_name": "Cardholder Name",
      "cardholder_postal_code": "Cardholder postal code",
      "cardholder_presence_indicator": "Cardholder presence indicator",
      "cardholder_state": "Cardholder state",
      "carholder_activated_terminal_indicator": "Cardholder activated terminal indicator",
      "carrier_code": "Carrier Code",
      "case_id": "Case ID",
      "case_number_arrest_code": "Case number Arrest Code",
      "cash_back_amount": "Cash back amount",
      "cash_back_currency_code": "Cash back currency code",
      "cashback_amount": "Cashback amount",
      "cashback_indicator": "Cashback indicator",
      "cat_level": "CAT level",
      "category": "Category",
      "central_processing_date": "Central processing date",
      "channel": "Channel",
      "chargeback_account_amount": "Chargeback account amount",
      "chargeback_account_currency": "Chargeback account currency",
      "chargeback_amount": "Chargeback amount",
      "chargeback_count": "Chargeback Count",
      "chargeback_currency": "Chargeback currency",
      "chargeback_indicator": "Chargeback indicator",
      "chargeback_ratio_count_in_percent": "Chargeback ratio count in %",
      "chargeback_ratio_volume_in_percent": "Chargeback ratio volume in %",
      "chargeback_reference_id": "Chargeback reference ID",
      "chargeback_volume": "Chargeback Volume",
      "chargeback_volume_in_usd": "Chargeback Volume In USD",
      "chart": "Chart",
      "check_in_date": "Check-in Date",
      "check_out_date": "Check-out Date",
      "city": "City",
      "city_of_origin": "City of origin",
      "clearing_service": "Clearing Service",
      "client_cert": "Client cert",
      "client_number": "Client number",
      "cnn": "CNN",
      "code": "Code",
      "code_10": "Code 10",
      "commerce_indicator": "Commerce indicator",
      "company_activity": "Company activity",
      "company_type": "Company type",
      "confidential": "Confidential",
      "consumer_email": "Consumer email",
      "consumer_id": "Consumer ID",
      "consumer_password": "Consumer Password",
      "consumer_reference": "Consumer Reference",
      "contact_email": "Contact Email",
      "contact_number": "Contact Number",
      "contract_acquirer": "Contract acquirer",
      "contract_descriptor": "Contract descriptor",
      "contract_gateway": "Contract gateway",
      "contract_id": "Contract ID",
      "contract_owner_id": "Contract owner ID",
      "contract_owner_type": "Contract owner type",
      "contract_terminal_token": "Contract Terminal token",
      "contractor_name": "Contractor Name",
      "corporate_payment_exemption_threshold": "Corporate Payment Exemption Threshold",
      "count": "Count",
      "count_approved": "Count Approved",
      "count_declined": "Count Declined",
      "count_not_approved": "Count not approved",
      "country": "Country",
      "country_iso_code": "Country ISO code",
      "country_of_origin": "Country of origin",
      "create_new_credentials": "Create new credentials",
      "created_at": "Created at",
      "created_by": "Created by",
      "credential1": "Credential1",
      "credential2": "Credential2",
      "cross_rate": "Cross rate",
      "cruise_end_date": "Cruise End Date",
      "cruise_start_date": "Cruise Start Date",
      "crypted_pan": "Crypted pan",
      "crypto": "Cryptocurrency",
      "css_url": "CSS URL",
      "currency": "Currency",
      "current_count": "Current Count",
      "custom_chart": "Custom chart",
      "custom_css": "Custom WPF CSS",
      "custom_data": "Custom data",
      "customer": "Customer",
      "customer_account_id": "Customer account number or ID",
      "customer_bank_id": "Bank",
      "customer_email": "Customer email",
      "customer_gender": "Customer gender",
      "customer_id": "Customer ID",
      "customer_name": "Customer name",
      "customer_phone": "Customer phone",
      "customer_posted_date": "Customer posted date",
      "customer_service_email": "Customer Service Email",
      "customer_service_number": "Customer Service Number",
      "cvv": "CVV",
      "cvv2_result": "CVV2 result",
      "cvv_result_code": "CVV Result",
      "date": "Date",
      "date_of_incorporation": "Date of Incorporation",
      "date_of_order": "Date Of Order",
      "date_range": "Date range",
      "days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "dcc_indicator": "DCC Indicator",
      "declined_credit_count": "Declined Credit Count",
      "declined_credit_volume": "Declined Credit Volume",
      "declined_credit_volume_in_usd": "Declined Credit Volume In USD",
      "default": "Default",
      "delegated_authentication_exemption_threshold": "Delegated Authentication Exemption Threshold",
      "delivery_date": "Delivery Date",
      "departure_date": "Departure Date",
      "deposit_slip_number": "Deposit slip number",
      "depth": "Depth",
      "description": "Description",
      "descriptor": "Descriptor",
      "descriptor_type": "Descriptor Type",
      "destination_city": "Destination City",
      "details": "Details",
      "did": "DID",
      "digitable_line": "Digitable line",
      "directory_server": "Directory Server",
      "directory_server_id": "3DS Directory Server ID",
      "disabled": "Disabled",
      "display_id": "Display Id",
      "dispute_condition_code": "Dispute condition code",
      "document_id": "Document ID",
      "document_indicator": "Document indicator",
      "document_type": "Document type",
      "domain": "Domain",
      "due_date": "Due date",
      "e_wallet_type": "E-wallet type",
      "eci": "ECI",
      "eci_sli": "ECI Sli",
      "ecom_type": "ECOM type",
      "edit_merchant_registration": "Edit Merchant Registration",
      "editable_by_user": "Editable by user",
      "electronic_commerce_indicator": "Electronic commerce indicator",
      "email": "Email",
      "email_body": "Email body",
      "email_notification": "Email notification",
      "email_subject": "Email subject",
      "email_use_html": "Email use html",
      "enabled": "Enabled",
      "enabled_money_transfer_types_json": "Enabled Money Transfer Types",
      "enabled_terminals": "Enabled terminals",
      "encrypt_type": "Encrypt Type",
      "end_date": "End Date",
      "enrollment_status": "Enrollment status",
      "entity_privileges": "Entity privileges",
      "error": "Error",
      "error_class": "Error class",
      "error_message": "Error message",
      "event_end_date": "Event End Date",
      "event_id": "Event Id",
      "event_organizer_id": "Event Organizer Id",
      "event_start_date": "Event Start Date",
      "exchange_rate": "Exchange Rate",
      "excluded_transaction_identifier_reason": "Excluded transaction identifier reason",
      "expiration_date": "Expiration date",
      "expiration_month": "Expiration month",
      "expiration_year": "Expiration year",
      "expires_at": "Expires at",
      "expiry_date": "Expiry Date",
      "extended_date_utc": "Extended Date Utc",
      "external_id": "External ID",
      "external_redirect_url": "External redirect URL",
      "external_response_code": "External response code",
      "external_response_message": "External response message",
      "failed_attempts": "Failed attempts",
      "failures": "Failures",
      "fee_amount": "Fee amount",
      "fee_currency": "Fee currency",
      "fee_description": "Fee Description",
      "fee_number": "Fee Number",
      "fee_percentage": "Fee Percentage",
      "fee_sequence_code": "Fee Sequence Code",
      "fee_sequence_description": "Fee Sequence Description",
      "fee_tier": "Fee Tier",
      "filter_settings": "Filter Settings",
      "finalized_at": "Finalized at",
      "first_date": "First Date",
      "first_name": "First Name",
      "flight_arrival_date": "Flight Arrival Date",
      "flight_departure_date": "Flight Departure Date",
      "flight_destination_city": "Flight Destination City",
      "flight_number": "Flight Number",
      "flight_origin_city": "Flight Origin City",
      "flight_ticket_number": "Flight Ticket Number",
      "form_layout": "Form layout",
      "fraud": "Fraud",
      "fraud_count": "Fraud Count",
      "fraud_investigative_code": "Fraud investigative code",
      "fraud_rate": "Fraud Rate",
      "fraud_ratio_count_in_percent": "Fraud ratio count in %",
      "fraud_ratio_volume_in_percent": "Fraud ratio volume in %",
      "fraud_type_code": "Fraud type code",
      "fraud_volume": "Fraud Volume",
      "fraud_volume_in_usd": "Fraud Volume In USD",
      "frequency": "Frequency",
      "from": "From",
      "fulfillment_date": "Fulfillment date",
      "funding_date": "Funding Date",
      "funds_status": "Funds status",
      "funds_status_updated_at": "Date of funds status",
      "fx_converted_amount": "FX converted amount",
      "fx_tier_id": "Tier ID",
      "fx_trading_rate": "FX trading rate",
      "gaming": "Gaming",
      "gateway": "Gateway",
      "gender": "Gender",
      "global_key_id": "Global key ID",
      "hostname": "Hostname",
      "hours": {
        "one": "1 hour",
        "other": "%{count} hours"
      },
      "http_code": "HTTP Code",
      "iban": "IBAN",
      "id": "ID",
      "id_card_number": "ID Card Number",
      "import_date": "Import date",
      "imported_at": "Imported at",
      "incorporation_date": "Company incorporation date",
      "industry_code": "Industry code",
      "info": "Info",
      "inquiry_date": "Inquiry Date",
      "inquiry_status": "Inquiry Status",
      "integration_type": "Integration type",
      "interchange_amount": "Interchange Amount",
      "interchange_amount_in_trx_currency": "Interchange Amount In Trx Currency",
      "interchange_category": "Interchange category",
      "interchange_currency": "Interchange Currency",
      "internal_merchant_account": "Internal merchant account",
      "internal_response_code": "Internal esponse code",
      "international_bank_account_number": "IBAN / Account No",
      "interval": "Interval",
      "ip_address": "IP Address",
      "iris_decline_reasons": "Risk decline reasons",
      "iris_rule_comment": "Rule Comment %{index}: %{decline_reason}",
      "is_template": "Is template",
      "issued_at": "Issued at",
      "issuer": "Issuer",
      "issuer_generated_authorization": "Issuer generated authorization",
      "issuer_number": "Issuer number",
      "issuer_oct_enabled": "Issuer OCT enabled",
      "issuing_bank_name": "Issuing Bank Name",
      "issuing_transit_routing_number": "Issuing transit routing number",
      "item_number": "Item number",
      "item_slip_number": "Item slip number",
      "item_type": "Item type",
      "item_type_code": "Item type code",
      "items": "Items",
      "job_id": "Job ID",
      "key_custodian_id": "Key custodian ID",
      "kyc_notification_params": "KYC Notification Params",
      "kyc_service_four_stop_password": "4S KYC Service Password",
      "kyc_service_four_stop_username": "4S KYC Service Username",
      "kyc_service_notification_url": "KYC Service Notification URL",
      "kyc_service_password": "KYC Service Password",
      "kyc_service_shufti_pro_password": "SP KYC Service Password",
      "kyc_service_shufti_pro_username": "SP KYC Service Username",
      "kyc_service_username": "KYC Service Username",
      "language": "Language",
      "last_activity_at": "Last login at",
      "last_inquiry_status": "Last Inquiry Status",
      "last_name": "Last Name",
      "lft": "lft",
      "lifetime": "Lifetime",
      "loaded": "Loaded",
      "local_amount_gross": "Local Gross Amount",
      "local_currency": "Local Currency",
      "locator_number": "Locator number",
      "locked_at": "Locked at",
      "login": "Login",
      "logo": "Logo",
      "low_risk_exemption_threshold": "Low Risk Exemption Threshold",
      "mailed_from_postal_code": "Mailed from postal code",
      "marital_status": "Marital status",
      "market_segment_code": "Market segment code",
      "master_account_currency": "Master Account Currency",
      "master_account_name": "Master Account Name",
      "mastercard_banknet_date": "Mastercard Banknet Date",
      "mastercard_banknet_id": "Mastercard Banknet ID",
      "mastercard_banknet_reference": "MasterCard baknet reference",
      "mastercard_banknet_reference_settle_date": "Mastercard banknet reference settle date",
      "max": "Max",
      "max_amount": "Max amount",
      "max_count": "Max Count",
      "mcc": "MCC",
      "member_message_text": "Member message text",
      "merch_settlement_amount": "Merch Settlement Amount",
      "merchant": "Merchant",
      "merchant_address": "Merchant Address",
      "merchant_bank_id": "Merchant Bank ID",
      "merchant_category_code": "Merchant category code",
      "merchant_certificate_password": "Merchant Certificate Password",
      "merchant_certificate_subject": "Merchant Certificate Subject",
      "merchant_city": "Merchant city",
      "merchant_country": "Merchant country",
      "merchant_country_code": "Merchant country code",
      "merchant_country_of_origin": "Merchant country of origin",
      "merchant_customer_id": "Merchant Customer ID",
      "merchant_dba_name": "Merchant DBA name",
      "merchant_financial_impact": "Merchant Financial Impact",
      "merchant_funding_amount": "Merchant funding amount",
      "merchant_funding_currency": "Merchant funding currency",
      "merchant_id": "Merchant ID",
      "merchant_ip": "Merchant IP",
      "merchant_name": "Merchant",
      "merchant_number": "Merchant number",
      "merchant_on_file": "Merchant on File",
      "merchant_owner_id": "Merchant owner ID",
      "merchant_owner_type": "Merchant owner type",
      "merchant_password": "Merchant Password",
      "merchant_phone": "Merchant phone",
      "merchant_post_code": "Merchant post code",
      "merchant_postal_code": "Merchant postal code",
      "merchant_service_city": "Merchant service city",
      "merchant_service_country": "Merchant service country",
      "merchant_service_phone": "Merchant service phone",
      "merchant_service_state": "Merchant service state",
      "merchant_service_zip_code": "Merchant service zip code",
      "merchant_settlement_amount": "Merchant settlement amount",
      "merchant_settlement_currency": "Merchant settlement currency",
      "merchant_state": "Merchant state",
      "merchant_state_code": "Merchant state code",
      "merchant_street": "Merchant street",
      "merchant_transaction_id": "Merchant Transaction ID",
      "merchant_transaction_reference": "Merchant Transaction Reference",
      "merchant_type": "Merchant Type",
      "merchant_url": "Merchant URL",
      "merchant_verification_value": "Merchant verification value",
      "merchant_website": "Merchant website",
      "merchant_zip_code": "Zip Code",
      "message": "Message",
      "message_type_id": "Message type ID",
      "mid": "MID",
      "min": "Min",
      "minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "mode": "Mode",
      "mothers_name": "Mother's name",
      "moto": "MOTO",
      "mpi": "MPI",
      "mpi_mode": "MPI mode",
      "multiple_clearing_sequence_number": "Multiple clearing sequence number",
      "name": "Name",
      "name_of_the_supplier": "Name Of The Supplier",
      "national_id": "National ID",
      "nationality": "Nationality",
      "neighborhood": "Neighborhood",
      "network_settlement_amount": "Network settlement amount",
      "network_settlement_currency": "Network settlement currency",
      "next_date": "Next Date",
      "notification_code": "Notification code",
      "notification_url": "Notification URL",
      "notifications_signature_algorithm": "Signature encryption algorithm",
      "notifyable_id": "Notifyable ID",
      "notifyable_type": "Notifyable type",
      "object_type": "%{object_type} Details",
      "occupation": "Occupation",
      "oheld_payment_status": "OHELD Payment Status",
      "omnipay_card_present_transaction_id": "Omnipay Card Present Transaction ID",
      "online_banking_banks": "Online Banking Banks",
      "operator": "Operator",
      "order_tax_amount": "Order tax amount",
      "origin_city": "Origin City",
      "original_batch_number": "Original batch number",
      "original_chargeback_slip": "Original chargeback slip",
      "original_merchant_settlement_currency": "Original Merchant Settlement Currency",
      "original_post_date": "Original post date",
      "original_slip": "Original Slip Number",
      "original_slip_number": "Original Slip Number",
      "original_transaction_amount": "Original transaction amount",
      "original_transaction_currency": "Original transaction currency",
      "original_transaction_date": "Original transaction date",
      "original_type": "Original type",
      "owner": "Owner",
      "owner_id": "Owner ID",
      "owner_type": "Owner type",
      "pan": "PAN",
      "pan_hash": "PAN Hash",
      "pan_tail": "Pan tail",
      "parent": "Parent",
      "parent_id": "Parent ID",
      "partial_amount": "Partial amount",
      "partially_approved_amount": "Partially Approved Amount",
      "pay_later": "Pay Later",
      "pay_pal_platform_fee_in_eur": "PayPal platform fee in EUR cents",
      "pay_pal_supported_features": "PayPal Supported Features",
      "payload": "Payload",
      "payload_type": "Payload Type",
      "payment_brand": "Payment brand",
      "payment_date": "Payment Date",
      "payment_id": "Payment ID",
      "payment_method": "Payment Method",
      "payment_method_category": "Payment method category",
      "payment_product": "Payment Product",
      "payment_subtype": "Payment Subtype",
      "payment_token": "Payment Token",
      "payment_transaction": "Payment transaction",
      "payment_transaction_id": "Payment transaction ID",
      "payment_transaction_link": "Payment Transaction",
      "payment_type": "Payment Type",
      "payout_count": "Payout Count",
      "payout_volume": "Payout Volume",
      "payout_volume_in_usd": "Payout Volume In USD",
      "percentage": "Percentage",
      "performed_cpf_checks": "Count of performed CPF/CNPJ checks",
      "period": "Period",
      "periodicity": "Periodicity",
      "phone": "Phone",
      "pick_up_date": "Pick-up Date",
      "pin": "Pin",
      "pix_key": "PIX Key",
      "pos_condition_code": "POS Condition Code",
      "pos_entry_method": "POS entry method",
      "pos_entry_mode": "POS Entry Mode",
      "pos_mode": "POS mode",
      "pos_terminal_capability": "POS terminal capability",
      "post_date": "Post date",
      "ppol_program_data": "Ppol program data",
      "preauthorization": "Preauthorization",
      "previous_transfer_to_merchant": "Previous transfer to merchant",
      "primary_address": "Primary Address",
      "priority": "Priority",
      "privacy_policy": "Privacy Policy",
      "privacy_policy_url": "Privacy policy URL",
      "processed_transaction": "Processed Transaction",
      "processed_transaction_id": "Processed transaction ID",
      "processed_transaction_missing": "Processed Transaction is missing",
      "processing_notification_additional_params": "Processing Notification Additional Params",
      "processing_type": "Processing type",
      "processor": "Processor",
      "product_code": "Product code",
      "product_desc": "Product description",
      "product_num": "Product number",
      "purchase_date": "Purchase date",
      "purpose": "Purpose",
      "pwd": "Password",
      "ran": "RAN",
      "rate": "Rate",
      "rc_code": "Reason Code",
      "rc_description": "Reason description",
      "rdr_account_amount": "RDR account amount",
      "rdr_account_currency": "RDR Account currency",
      "rdr_amount": "RDR Amount",
      "rdr_currency": "RDR Currency",
      "reason_code": "Reason Code",
      "reason_description": "Reason description",
      "received_at": "Received At",
      "received_date": "Received date",
      "recipients": "Recipients",
      "reconciled_at": "Reconciled at",
      "reconciliation_timeframe": "Reconciliation timeframe",
      "record_id": "Record ID",
      "recurring_advice_code": "Recurring advice code",
      "recurring_advice_text": "Recurring advice text",
      "recurring_category": "Recurring Category",
      "recurring_type": "Recurring Type",
      "redirect_uri": "Redirect URI",
      "ref": "Ref.",
      "reference_id": "Reference ID",
      "reference_processed_transaction": "Processed Transaction",
      "reference_transaction_id": "Reference transaction ID",
      "reference_transaction_type": "Reference Transaction Type",
      "reference_transaction_unique_id": "Reference Transaction Unique Id",
      "refund_count": "Refund Count",
      "refund_email": "Refund email",
      "refund_ratio_count_in_percent": "Refund ratio count in %",
      "refund_ratio_volume_in_percent": "Refund ratio volume in %",
      "refund_timeframe": "Refund timeframe",
      "refund_volume": "Refund Volume",
      "refund_volume_in_usd": "Refund Volume In USD",
      "refunded_amount": "Refunded Amount",
      "region": "Region",
      "region_class": "Region class",
      "register_merchant": "Register Merchant",
      "registered_name": "Registered Name",
      "registration_number": "Registration Number",
      "registration_reference_number": "Registration reference number",
      "reimbursement_attribute": "Reimbursement attribute",
      "related_acquirer_entity": "Related Acquirer Entity",
      "remember_card": "Remember card",
      "remote_ip": "Remote IP",
      "remote_local": "Remote Local",
      "remote_test": "Remote test",
      "renewed_card_number": "Renewed Card Number",
      "rental_agreement_number": "RAN",
      "report_date": "Report Date",
      "representative_id": "Representative ID",
      "representative_name": "Representative Name",
      "representment_count": "Representment Count",
      "representment_ratio_count_in_percent": "Representment ratio count in %",
      "representment_ratio_volume_in_percent": "Representment ratio volume in %",
      "representment_volume": "Representment Volume",
      "representment_volume_in_usd": "Representment Volume In USD",
      "request_id": "Request Id",
      "required_api_params": "Required API Params",
      "required_business_attributes": "Required Business Attributes",
      "required_business_params": "Required Business Params",
      "requires_cvv": "Requires CVV",
      "reseller": "Reseller",
      "reseller_id": " Supermerchant/Reseller",
      "reseller_subtype": "Reseller subtype",
      "response": "Response",
      "response_code": "Response code",
      "retries": "Retries",
      "retrieval_reference": "Retrieval reference",
      "retrieval_reference_number": "RRN",
      "retrieval_request_id": "Retrieval request ID",
      "retry": "Retry",
      "retry_count": "Retry count",
      "return_cancel_url": "Return cancel URL",
      "return_date": "Return Date",
      "return_failure_url": "Return failure URL",
      "return_pending_url": "Return pending URL",
      "return_success_url": "Return success URL",
      "return_url": "Return URL",
      "reversal_indicator": "Reversal Indicator",
      "reversed": "Reversed",
      "reviewed_by": "Reviewed by",
      "rgt": "rgt",
      "risk_params": "Risk params",
      "role": "Role",
      "rr_date": "RR Date",
      "rrn": "Merchant transaction reference",
      "rt_flag": "Rt flag",
      "saved_cards": "Saved Cards",
      "scheme_custom_transaction_code": "Scheme custom transaction code",
      "scheme_date": "Date of registration with the Scheme",
      "scheme_fee": "Scheme Fee",
      "scheme_fee_currency": "Scheme Fee Currency",
      "scheme_file_reference": "Scheme file reference",
      "scheme_issuer_settlement_amount": "Scheme Issuer Settlement Amount",
      "scheme_response_code": "Scheme Response Code",
      "scheme_settlement_date": "Scheme Settlement Date",
      "scheme_transaction_id": "Scheme Transaction Id",
      "scheme_transaction_link_id": "Scheme Transaction Link Id",
      "scopes": "Scopes",
      "search": "search",
      "secondary_address": "Secondary Address",
      "seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "secure_code": "Secure code",
      "security_capture_indicator": "Security Capture Indicator",
      "sender_occupation": "Occupation",
      "serial_id_number": "Serial ID number",
      "service_id": "Service ID",
      "service_id_code": "Service ID code",
      "service_name": "Service name",
      "service_type": "Service Type",
      "service_type_desc": "Service type desc",
      "session_id": "Session ID",
      "settlement_number": "Settlement Number",
      "settlement_status_raw": "Settlement status raw",
      "settlement_transaction_currency": "Settlement Transaction Currency",
      "shareholder_id": "Shareholder ID",
      "shareholder_name": "Shareholder Name",
      "show_shipping_address": "Show shipping address",
      "site_id": "Site ID",
      "site_name": "Site Name",
      "site_type": "Site Type",
      "site_url": "Site URL",
      "social_security_number": "Social security number",
      "spend_qualified_indicator": "Spend Qualified Indicator",
      "standard_debit_card_rate": "Standard Debit Card Rate",
      "start_date": "Start Date",
      "state": "State",
      "state_raw": "State (raw)",
      "status": "Status",
      "status_transition_from": "Transition from (status)",
      "status_transition_to": "Transition to (status)",
      "store": "Store",
      "street_address": "Street Address",
      "sub_fraud_type_code": "Sub fraud type code",
      "sub_merchant_id": "Sub-Merchant ID",
      "submitted_at": "Submitted at",
      "subscription_status": "Subscription Status",
      "supplier_name": "Supplier Name",
      "suppliers": "Suppliers",
      "supported_features": "Supported features",
      "supported_transaction_types": "Supported transaction types",
      "tac_url": "TaC URL",
      "target": "Target",
      "telephone_number": "Telephone number",
      "template_id": "Template ID",
      "templates": "Templates",
      "terminal": "Terminal",
      "terminal_attendance_indicator": "Terminal attendance indicator",
      "terminal_capability": "Terminal Capability",
      "terminal_capability_code": "Terminal capability code",
      "terminal_capability_indicator": "Terminal capability indicator",
      "terminal_id": "Terminal ID",
      "terminal_name": "Terminal",
      "terminal_operating_code": "Terminal operating code",
      "terminal_owner_id": "Terminal owner ID",
      "terminal_owner_type": "Terminal owner type",
      "terminal_token": "Terminal Token",
      "terminated_at": "Terminated at",
      "terms_and_conditions": "Terms & Conditions",
      "threeds_authentication_flow": "3DS authentication flow",
      "threeds_authentication_protocol": "3DS authentication protocol",
      "threeds_challenge_indicator": "3DS Challenge Indicator",
      "threeds_method": "3DS-Method",
      "threeds_method_status": "3DS-Method status",
      "threeds_method_wait_seconds": "3DS-Method Wait Seconds",
      "threeds_server_transaction_id": "3DS Server transaction ID",
      "threeds_workflow": "3DS workflow",
      "ticket_expiry_date": "Ticket expiry date",
      "ticket_number": "Ticket Number",
      "ticket_reference_id": "Ticket Reference ID",
      "time": "Time",
      "time_of_day": "Time of Day",
      "timeout_seconds": "Timeout seconds",
      "title": "Title",
      "to": "To",
      "token": "Token",
      "token_id": "Token ID",
      "token_type": "Token type",
      "total_amount": "Total amount",
      "total_count": "Total Count",
      "total_volume": "Total Volume",
      "total_volume_in_usd": "Total Volume In USD",
      "tour_operator_name": "Tour Operator Name",
      "trace": "Trace",
      "trace_id_number": "Trace ID number",
      "trading_name": "Trading Name",
      "traffic_shaper_class": "Traffic shaper class",
      "transaction_amount": "Transaction Amount",
      "transaction_category": "Transaction Category",
      "transaction_category_code": "Transaction Category Code",
      "transaction_currency": "Transaction Currency",
      "transaction_date": "Transaction date",
      "transaction_descriptor": "Transaction descriptor",
      "transaction_gross_amount": "Transaction Gross Amount",
      "transaction_id": "Transaction ID",
      "transaction_identifier": "Transaction identifier",
      "transaction_missing": "Payment Transaction is missing",
      "transaction_reference": "Transaction Reference",
      "transaction_status_code": "Transaction status code",
      "transaction_time": "Transactions",
      "transaction_type": "Transaction type",
      "transaction_type_code": "Transaction type code",
      "transaction_types": "Transaction types",
      "transaction_with_forced_authorization_due_to_3ds_network_outage": "non-3DS authorization in case of 3DS authentication network outage",
      "transactions": "Transactions",
      "transition": "Transition",
      "travel_agency": "Travel Operator",
      "travel_agency_id": "Travel agency ID",
      "travel_agency_name": "Travel Agency Name",
      "travel_end_date": "Travel End Date",
      "travel_end_date_export": "Travel End Date",
      "travel_start_date": "Travel Start Date",
      "travel_start_date_export": "Travel Start Date",
      "travel_type": "Travel Type",
      "travel_type_export": "Travel Type",
      "trusted_merchant_exemption_threshold": "Trusted Merchant Exemption Threshold",
      "two_factor_auth_enabled": "Two factor authentication enabled",
      "type": "Type",
      "ucaf": "Ucaf",
      "ucaf_indicator": "UCAF Indicator",
      "unique_id": "Unique ID",
      "unique_tran_id": "Unique tran ID",
      "unique_transaction_identifier": "Unique Transaction Identifier",
      "updated_at": "Updated At",
      "url": "URL",
      "usage": "Usage",
      "user_id": "User ID",
      "validated": "Validated",
      "value": "Value",
      "value_date": "Value Date",
      "variables": "Variables",
      "vbv_result": "VBV Result",
      "vehicle_pick_up_date": "Vehicle Pick-up Date",
      "vehicle_return_date": "Vehicle Return Date",
      "verification_code": "Verification Code",
      "vic_processing_date": "VIC processing date",
      "visa_transaction_id": "VISA transaction ID",
      "vme_add_auth_method": "VME add auth method",
      "vme_add_auth_reason_code": "VME add auth reason code",
      "voice": "Voice",
      "volume_approved": "Volume Approved",
      "volume_approved_in_usd": "Approved Volume In USD",
      "volume_declined": "Volume Declined",
      "volume_declined_in_usd": "Volume Declined In USD",
      "volume_not_approved": "Volume not approved",
      "voucher_number": "Voucher Number",
      "wallet_id": "Wallet ID",
      "with_alternative_3ds_form_submission": "Use alternative 3DS Challenge form submission",
      "with_low_risk_exemption_auto_apply_for_3ds": "Auto TRA exemption with in-house 3DS flow",
      "work_by_date": "Work by date",
      "wpf_custom_theme_json": "WPF Custom theme",
      "wpf_custom_theme_json_v2": "WPF Designer",
      "wpf_notification_additional_params": "WPF Notification Additional Params",
      "wpf_payment_id": "WPF payment id",
      "wpf_payment_lifetime": "WPF Payment Lifetime",
      "wpf_retry_count": "WPF retry count",
      "wpf_theme": "WPF Theme",
      "wpf_theme_v2": "WPF Theme V2",
      "wpf_traffic_shaper_class": "WPF traffic shaper class",
      "zip_code": "Zip Code"
    },
    "names": {
      "account_owner_attributes": "Account Owner",
      "acquirer": "Acquirer",
      "acquirer_bin": "Acquirer bin",
      "address": "Address",
      "agent": "Agent",
      "agents": "Agents",
      "analysis": "Analysis",
      "analysis_stats": "Analysis Stats",
      "apple_pay_certificate": "Payment Processing Certificate",
      "billing_address": "Billing Address",
      "billing_statement": "Billing Statement",
      "billing_statements": "Billing Statements",
      "billing_transactions": "Billing Transactions",
      "blacklist": "Blacklist",
      "blacklists": "Blacklists",
      "brasilian_taxpayers": "CPF/CNPJ service logs",
      "brazilian_taxpayer": "CPF/CNPJ service log",
      "brazilian_taxpayers": "CPF/CNPJ service logs",
      "business_attributes": "Business Attributes",
      "business_specific_attributes": "Business Specific Attributes",
      "card_account_inquiries": "Card Account Inquiries",
      "card_account_updates": "Card Account Updates",
      "card_accounts": "Card Accounts",
      "card_brand": "Card Brand",
      "card_brands": "Card Brands",
      "card_present_authorization": "Omnipay Card Present Authorization",
      "card_present_chargeback": "Omnipay Card Present Chargeback",
      "card_present_funding_accounts": "Omnipay Card Present Funding Accounts",
      "card_present_interchange_detail": "Omnipay Card Present Interchange Detail",
      "card_present_rapid_dispute_resolution": "Card Present Rapid Dispute Resolution",
      "card_present_rapid_dispute_resolutions": "Card Present Rapid Dispute Resolutions",
      "card_present_retrieval_request": "Card Present Retrieval Request",
      "card_present_transaction": "Card Present transaction",
      "chargeback": "Chargeback",
      "chargebacks": "Chargebacks",
      "companies": "Companies",
      "company": "Company",
      "configurable_web_payment_form": "Web Payment Form",
      "configurable_web_payment_forms": "Web Payment Forms",
      "consumer": "Consumer",
      "consumers": "Consumers",
      "contract": "MID",
      "contract_acquirer": "Acquirer",
      "contract_reconcile_log": "MID Reconciliation log",
      "contracts": "MIDs",
      "currencies": "Currencies",
      "currency": "Currency",
      "email_configuration": "Email Configuration",
      "email_configurations": "Email Configurations",
      "email_template": "Email Template",
      "email_templates": "Email Templates",
      "export_files": "Export Files",
      "front_end_reject": "Front End Reject",
      "front_end_rejects": "Front End Rejects",
      "funding_account": "Funding Account",
      "funding_accounts": "Funding Accounts",
      "fx_log": "FX Log",
      "fx_rate": "Fx Rate",
      "fx_rates": "Fx Rates",
      "generate_report": "Generate report",
      "interchange_details": "Interchange Details",
      "interchange_reject": "Interchange Reject",
      "invalid_params_for": "Invalid params for",
      "ip_address": "IP Address",
      "ipsp": "IPSP",
      "ipsps": "IPSPs",
      "iso": "ISO",
      "isos": "ISOs",
      "kyc_service_log": "KYC Service Log",
      "kyc_service_logs": "KYC Service Logs",
      "kyc_services": "Kyc Services",
      "log": "Log",
      "logs": "Logs",
      "managed_recurring_item": "Managed Recurring Item",
      "managed_recurring_items": "Managed Recurring Items",
      "mastercard_fraud_report": "SAFE",
      "mastercard_fraud_reports": "SAFE",
      "merchant": "Merchant",
      "merchant_address": "Merchant Address",
      "merchant_id": "Merchant ID",
      "merchants": "Merchants",
      "notification": "Notification",
      "notification_url": "Notification URL",
      "notification_urls": "Notification URLs",
      "notifications": "Notifications",
      "oauth_application": "OAuth Application",
      "oauth_applications": "OAuth Applications",
      "omnipay_card_present_funding_account": "Omnipay Card Present Funding Account",
      "omnipay_card_present_funding_accounts": "Omnipay Card Present Funding Accounts",
      "paycommerce_batches": "Paycommerce Batches",
      "payment_facilitator": "Payment Facilitator",
      "payment_facilitators": "Payment Facilitators",
      "payment_transaction": "Payment transaction",
      "payment_transactions": "Payment Transactions",
      "payout_sender_address": "Sender address",
      "period": "Period",
      "processed_transaction": "Processed transaction",
      "processed_transactions": "Processed Transactions",
      "processing_log": "Log",
      "psp": "PSP",
      "psps": "PSPs",
      "rapid_dispute_resolution": "Rapid Dispute Resolution",
      "rapid_dispute_resolutions": "Rapid Dispute Resolutions",
      "reconcile_log": "Reconciliation Log",
      "reminders_config": "Reminders Config",
      "reseller": "Reseller",
      "resellers": "Resellers",
      "retrieval_request": "Retrieval Request",
      "retrieval_requests": "Retrieval Requests",
      "return_url": "Return URL",
      "return_urls": "Return URLs",
      "risk_management": "Risk Management",
      "settlement_report": "Settlement report",
      "settlement_report_details": "Settlement report details",
      "settlement_report_invalid_merchant": "Invalid merchant",
      "shipping_address": "Shipping Address",
      "slow_transactions": "Slow Transactions",
      "summary_report": "Summary report",
      "supermerchant": "Supermerchant",
      "supermerchants": "Supermerchants",
      "terminal": "Terminal",
      "terminals": "Terminals",
      "token_sharing_group": "Token Sharing group",
      "token_sharing_groups": "Token Sharing groups",
      "transaction": "Transition",
      "transaction_attempts": "Transaction attempts",
      "transaction_date_and_time": "Transaction Date & Time",
      "transaction_notes": "Transaction Notes",
      "transactions": "Transactions",
      "user": "User",
      "users": "Users",
      "visa_fraud_report": "TC40",
      "visa_fraud_reports": "TC40",
      "whitelists": "Whitelists",
      "wpf_custom_theme": "WPF Custom Theme",
      "wpf_payment": "WPF Payment",
      "wpf_payments": "WPF Payments"
    },
    "status": {
      "approved": "Approved",
      "both": "Both",
      "chargeback_reversed": "Chargeback reversed",
      "chargebacked": "Chargebacked",
      "declined": "Declined",
      "disabled": "Disabled",
      "enabled": "Enabled",
      "error": "Error",
      "in_progress": "In progress",
      "loading": "Loading...",
      "new": "New",
      "partially_reversed": "Partially reversed",
      "pending": "Pending",
      "pending_async": "Pending async",
      "pending_hold": "Pending hold",
      "pending_review": "Pending review",
      "refunded": "Refunded",
      "represented": "Represented",
      "representment_reversed": "Representment Reversed",
      "retrieval_request": "Retrieval Request",
      "second_chargebacked": "Second Chargebacked",
      "voided": "Voided"
    }
  },
  "preferences": {
    "edit": {
      "change_password": "Change password",
      "current_password": "Current Password",
      "customizable_columns": "Customizable Columns",
      "first_name": "First Name",
      "last_name": "Last Name",
      "new_password": "New password",
      "new_password_confirmation": "New password confirmation",
      "preferences": "Preferences",
      "select_all": "Select All",
      "update": "Update",
      "update_user": "Update user",
      "user_details": "User details"
    }
  },
  "shared": {
    "actions": {
      "are_you_sure": "Are you sure?",
      "cancel": "Cancel",
      "configurations": "%{object_type} Configurations",
      "continue": "Continue",
      "copy": "Copy",
      "default": {
        "actions": "Actions",
        "advanced_search": "Advanced search",
        "apply_filter": "Apply filter",
        "are_you_sure": "Are you sure?",
        "delete": "Delete",
        "edit": "Edit",
        "export_all_files": "Export All Files",
        "export_files": "Export Files",
        "export_selected_files": "Export All Files",
        "export_to_csv": "Export to CSV",
        "export_to_excel": "Export Excel",
        "please_select": "Please select...",
        "remove": "Remove",
        "reset": "Reset",
        "search": "Search",
        "view_details": "View Details"
      },
      "disable": "Disable",
      "edit": "Edit",
      "edit_object": "Edit %{object_type}",
      "enable": "Enable",
      "export_entity": "%{entity} export has been scheduled.",
      "hide": "Hide",
      "save": "Save",
      "save_object": "Save %{object}",
      "show": "Show",
      "update": "Update",
      "update_object": "Update %{object}",
      "yes": "Yes"
    }
  }
});
